<template>
  <div>
    <BaseLayout>
      <template #header>
        <h1 class="text-uppercase">imltd</h1>
        <h2>Darah Reaktif</h2>
      </template>
      <template #content>
        <b-row class="d-flex justify-content-center">
          <b-col cols="auto">
            <div class="table-wrapper w-100 mx-auto overflow-scroll">
              <barcode-checker
                v-model="samplesCollected"
                :api-service="apiService"
                mode="onlyOne"
                @barcode-added="handleBarcodeAdded"
              />
            </div>
          </b-col>

          <b-col cols="12" class="d-flex justify-content-around ml-5">
            <b-form-group label="HIV" label-for="hiv-input">
              <b-form-input
                id="hiv-input"
                v-model="formData.hiv"
                type="text"
                aria-placeholder="Masukkan Nilai HIV"
                placeholder="Masukkan Nilai HIV"
              />
            </b-form-group>
            <b-form-group label="Syphilis" label-for="syphilis-input">
              <b-form-input
                id="syphilis-input"
                v-model="formData.syphillis"
                type="text"
                aria-placeholder="Masukkan Nilai Syphilis"
                placeholder="Masukkan Nilai Syphilis"
              />
            </b-form-group>
            <b-form-group label="Hepatitis B (HBsAg)" label-for="hbsag-input">
              <b-form-input
                id="hbsag-input"
                v-model="formData.hbsag"
                type="text"
                aria-placeholder="Masukkan Nilai HBsAg"
                placeholder="Masukkan Nilai HBsAg"
              />
            </b-form-group>
            <b-form-group label="Hepatitis C (HCV)" label-for="hcv-input">
              <b-form-input
                id="hcv-input"
                v-model="formData.hcv"
                type="text"
                aria-placeholder="Masukkan Nilai HCV"
                placeholder="Masukkan Nilai HCV"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mt-3 mb-3"
            @click="postCollectedReactiveSamples"
          >
            <b-spinner v-if="isLoading" small />
            Simpan
          </b-button>
        </div>
      </template>
    </BaseLayout>
    <b-row class="d-flex justify-content-center mt-5">
      <b-col cols="auto">
        <TableListIMLTD list-type="reaktif" />
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center mt-5">
      <b-col cols="auto">
        <TableListIMLTD list-type="greyzone" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BTable,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ScreeningAPI from "@/api/screening/screeningAPI";
import BarcodeChecker from "@/components/shared/BarcodeChecker.vue";
import TableListIMLTD from "./components/TableListIMLTD.vue";
import { EventBus } from "../../../../helpers/eventBus";

export default {
  name: "ListScreeningReactiveSampleIMLTD",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BarcodeChecker,
    TableListIMLTD,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      // data untuk memastikan barcode memiliki jeda dalam menampilkan data
      debounceCheckedBarcode: null,
      // form data yang akan dikirimkan
      formData: {
        barcode: "",
        syphillis: "",
        hbsag: "",
        hcv: "",
        hiv: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
          sortable: true,
        },
        {
          key: "barcode",
          label: "Barcode",
          sortable: false,
        },
        {
          key: "blood_bag_number",
          label: "Nomor Selang",
          sortable: true,
        },
        {
          key: "blood_type",
          label: "Golongan Darah",
        },
        {
          key: "bag_type",
          label: "Jenis Kantong",
        },
      ],
      // untuk menampilkan data items pada table non-reactive
      items: [],
      // data untuk menampung sample non-reaktif
      samplesCollected: "",
    };
  },
  methods: {
    apiService(payload) {
      return ScreeningAPI.barcodeScreening(payload);
    },
    handleBarcodeAdded(newBarcode) {
      this.samplesCollected = newBarcode;
    },
    async postCollectedReactiveSamples() {
      this.isLoading = true;

      try {
        const payload = {
          barcode: this.samplesCollected,
          syphilis: this.formData.syphillis,
          hepatitis_b: this.formData.hbsag,
          hepatitis_c: this.formData.hcv,
          hiv_aids: this.formData.hiv,
        };

        const response = await ScreeningAPI.addScreeningReactive(payload);

        this.$bvToast.toast(response.data.message, {
          title: "Data Berhasil Dikirim",
          variant: "success",
          solid: true,
        });

        EventBus.$emit("clearData");
        EventBus.$emit("reloadData");

        this.formData.barcode = "";
        this.formData.hiv = "";
        this.formData.syphillis = "";
        this.formData.hbsag = "";
        this.formData.hcv = "";
      } catch (error) {
        this.$bvToast.toast(
          error.response?.data?.message || "Terjadi kesalahan",
          {
            title: "Data Gagal Dikirim",
            variant: "danger",
            solid: true,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
